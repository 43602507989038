.project-grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: center;
  grid-gap: 8px;
}

.image {
  height: 270px;
}

.grid-box:nth-last-child() {
  align-items: center;
}
