.footer-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding-bottom: 20px;
  gap: 20px;
}

.footer-icon {
  width: 40px;
  height: 40px;
  user-select: none;
}

.footer-icon:hover {
  cursor: pointer;
  transform: scale(1.3);
  opacity: 0.6;
  transition: transform 0.5s, opacity 0.5s;
}
