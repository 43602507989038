body {
  scroll-padding-top: 50px;
}

.grid-container {
  max-width: 100%;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 20px;
  margin: 0 10px;
}

.grid-item {
  padding: 20px;
  border: 4px solid red;
}

.col-12 {
  grid-column: span 12;
}

.col-11 {
  grid-column: span 11;
}

.col-10 {
  grid-column: span 10;
}

.col-9 {
  grid-column: span 9;
}

.col-8 {
  grid-column: span 8;
}

.col-7 {
  grid-column: span 7;
}

.col-6 {
  grid-column: span 6;
}

.col-5 {
  grid-column: span 5;
}

.col-4 {
  grid-column: span 4;
}

.col-3 {
  grid-column: span 3;
}

.col-2 {
  grid-column: span 2;
}

.col-1 {
  grid-column: span 1;
}

.portrait {
  border-radius: 360px;
  height: 480px;
  width: 480px;
  align-items: center;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}

.modal-content {
  position: fixed;
  background: rgba(0, 0, 0, 0.9);
  width: 50%;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.close-button {
  float: right;
  width: 1.5rem;
  line-height: 1.5rem;
  text-align: center;
  cursor: pointer;
  border-radius: 0.25rem;
  background-color: darkgray;
  padding-bottom: 2px;
  margin-bottom: 5px;
}

.close-button:hover {
  background-color: lightgray;
  transition: background-color 0.7s;
}

.modal-header {
  padding: 0.5rem;
}

.modal-body {
  padding: 1rem;
}

@media (max-width: 768px) {
  .grid-item {
    grid-column: span 12;
  }
}
