.grid-container {
  max-width: 100%;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 20px;
  margin: 0 10px;
}

.grid-item {
  padding: 20px;
  border: 4px solid red;
}

.col-12 {
  grid-column: span 12;
}

.col-11 {
  grid-column: span 11;
}

.col-10 {
  grid-column: span 10;
}

.col-9 {
  grid-column: span 9;
}

.col-8 {
  grid-column: span 8;
}

.col-7 {
  grid-column: span 7;
}

.col-6 {
  grid-column: span 6;
}

.col-5 {
  grid-column: span 5;
}

.col-4 {
  grid-column: span 4;
}

.col-3 {
  grid-column: span 3;
}

.col-2 {
  grid-column: span 2;
}

.col-1 {
  grid-column: span 1;
}

.portrait {
  border-radius: 360px;
  height: 480px;
  width: 480px;
  align-items: center;
}

.button-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
}

.button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 0;
  border-radius: 0.375rem; /* Equivalent to 'rounded' */
  padding: 10px 24px; /* Vertical and horizontal padding */
  text-align: center;
  text-decoration: none;
  transition: background-color 0.3s;
  width: 200px; /* Fixed width for larger screens */
}

/* Adjustments for smaller screens */
@media (max-width: 768px) {
  .button {
    width: 150px; /* Smaller fixed width for medium screens */
  }
}

@media (max-width: 480px) {
  .button {
    width: 100%; /* Full width for smaller screens */
  }
}

.view-work-button {
  background-color: #667eea; /* Indigo 500 */
  color: white;
}

.view-work-button:hover {
  background-color: #5a67d8; /* Indigo 600 */
}

.contact-me-button {
  background-color: #edf2f7; /* Gray 200 */
  color: #4a5568; /* Gray 700 */
}

.contact-me-button:hover {
  background-color: #e2e8f0; /* Gray 300 */
}

.image {
  width: 100%;
  height: 100%;
}
